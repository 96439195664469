@import 'styles/breakpoints';
@import 'styles/animations';

.cardGroup {
    position: relative;
    overflow: hidden;
    padding: var(--block-padding-end) 0;
    background-color: var(--background-color, var(--colors-cream));

    &.featured {
        background-color: var(--colors-blue);
    }

    &.latest {
        background-color: var(--colors-white);
    }

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.recipes {
        --mask-height: 1.6rem;

        &::after {
            position: absolute;
            top: 0;
            left: -0.1rem;
            width: 101%;
            height: var(--mask-height);
            background: var(--colors-cream);
            content: '';
            mask-image: url('/images/jagged-border.svg');
            mask-position: top;
            mask-repeat: no-repeat;
            mask-size: cover;
            transform: rotate(180deg);
        }
    }

    &.products {
        padding-top: 3.6rem;
    }
}

.recipeProducts {
    width: calc(100% + var(--grid-padding-side));
    padding: 3.6rem 0;

    ul > li:last-child {
        margin-right: var(--grid-padding-side);
    }

    .eyebrow {
        padding-bottom: 3.6rem;

        span {
            font-size: 1.8rem;
        }
    }
}

.eyebrow {
    padding-bottom: var(--block-padding-end);
    color: var(--colors-blue);

    .featured & {
        max-width: 60rem;
        color: var(--colors-cream);
    }

    .recipes & {
        position: relative;
        overflow: hidden;
        padding: 0 var(--grid-padding-side) var(--grid-padding-side);

        h2 {
            z-index: 1;
            padding: 0 1.2rem;
            background-color: var(--colors-white);
        }

        span {
            font-size: 1.8rem;
        }

        &::after {
            position: absolute;
            top: 0.9rem;
            left: var(--grid-padding-side);
            width: calc(100% - var(--grid-padding-side) * 2);
            height: 0.1rem;
            background-color: var(--colors-blue);
            content: '';
            opacity: 0.2;
        }
    }

    .products & {
        padding-bottom: 3.6rem;
    }
}

.featuredBg {
    position: absolute;
    top: var(--featuredBgSvg-top, 9rem);
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        height: 70%;
        background-color: var(--colors-blueDark);
        content: '';
    }
}

.inlineIntroSmall {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 4.8rem;
    text-align: center;

    .headline,
    .copy,
    .eyebrow {
        color: var(--inline-intro-color, var(--colors-blue));
    }

    .copy {
        padding: 2.4rem 0 0;
        margin-bottom: 0;
    }

    .cta {
        margin: 2.4rem auto 0;
    }

    .eyebrow {
        padding-bottom: 0;
    }
}

.cta {
    margin: var(--block-padding-end) auto 0 auto;
}

.cardGroupCarousel {
    position: relative;
    width: 100%;
}

.inlineIntroContent {
    position: absolute;
    top: 4.8rem;
    left: 50%;
    display: none;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    margin: 0 auto;
    transform: translateX(-50%);
}

.inlineIntroContentInner {
    position: absolute;
    left: 0;
    width: 37vw;
    max-width: calc(var(--block-max-width) / 3);
    flex-direction: column;
    padding: 0;
    padding-left: var(--block-padding-side);
}

@include medium {
    .inlineIntroSmall {
        display: none;
    }

    .inlineIntroContent {
        display: flex;
    }

    .recipeProducts {
        padding: 4.8rem 0;

        .eyebrow {
            padding-bottom: 4.8rem;

            span {
                font-size: 2.4rem;
            }
        }
    }

    .recipes {
        .eyebrow {
            span {
                font-size: 2.4rem;
            }
        }
    }
}

@include large {
    .featuredBg {
        &::after {
            top: 90%;
        }
    }

    .recipeProducts {
        .eyebrow {
            padding-bottom: 4.8rem;

            span {
                font-size: 3rem;
            }
        }
    }

    .recipes {
        .eyebrow {
            padding-bottom: 4.8rem;

            h2 {
                padding: 0 2.4rem;
            }

            span {
                font-size: 3rem;
            }

            &::after {
                top: 1.5rem;
            }
        }
    }

    .cardGroup.products {
        padding-top: 4.8rem;

        .eyebrow {
            padding-bottom: 4.8rem;
        }
    }

    .inlineIntroContentInner {
        max-width: 60rem;
    }
}
