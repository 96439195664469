@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.blockLinkList {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;

    &.flushTop {
        padding-top: 0;
    }
}

.inner {
    width: 100%;
    padding: 4.8rem 0;
    background-color: var(--colors-white);
    color: var(--colors-blue);

    @include rounded-corners;
}

.eyebrow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;

    h2 {
        max-width: 30rem;
        margin: 0 auto;
    }
}

.links {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
        margin-bottom: 2.8rem;
    }
}

.cta {
    width: fit-content;
}

@include medium {
    .blockLinkList {
        padding: var(--block-padding-side);
    }

    .inner {
        max-width: var(--block-max-width);
        padding: var(--block-padding-side);
    }

    .linkText {
        padding: 2.3rem 0;
    }
}

@include large {
    .inner {
        display: flex;
    }

    .eyebrow {
        padding: 0;

        h2 {
            max-width: unset;
        }

        h3 {
            margin-bottom: 2.4rem;
        }
    }

    .column {
        position: relative;
        width: 65%;
        padding: 0 0 0 5%;

        &:first-child {
            display: flex;
            width: 35%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 5% 0 0;
            margin-bottom: 0;

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                height: 9rem;
                border-right: 1px solid rgba(color('blue'), 0.25);
                margin-top: -4.5rem;
                content: '';
            }
        }
    }
}

@include xxLarge {
    .column {
        padding: 0 0 0 8%;
    }
}
