@import 'styles/breakpoints';
@import 'styles/mixins';

.blockTwoColumnRichText {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-blue);
    color: var(--colors-cream);

    @include rounded-corners($overflow: hidden);

    &.whiteBackground {
        background-color: var(--colors-white);
        color: var(--colors-blue);
    }
}

.column {
    position: relative;
    width: 100%;
    height: 100%;

    &:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 4.8rem 10%;
    }
}

.image {
    position: absolute;
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.imageWrap {
    position: relative;
    height: 0;
    padding-bottom: 125%;
}

// rich text overrides
.richTextHeadingFour {
    margin: 2.7rem 0 0;
    text-transform: uppercase;
}

.richTextParagraph {
    margin: 0.7rem 0 0;
}

@include large {
    .inner {
        flex-direction: row-reverse;

        .imageOnLeft & {
            flex-direction: row;
        }
    }

    .imageWrap {
        padding-bottom: 172%;
    }

    .column {
        width: 50%;

        &:last-child {
            padding: 4rem 10%;
        }
    }
}

@include xxLarge {
    .imageWrap {
        padding-bottom: 125%;
    }

    .column {
        &:last-child {
            padding: 4rem 13rem;
        }
    }
}
