@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    --hover-offset: 3rem;
    --slide-offset: 2.4rem;
    --slide-spacing: 1.2rem;

    width: 100%;

    &.recipes {
        --slide-spacing: 1rem;

        article {
            height: 100%;
        }

        section > div:last-child > button {
            top: 33%;
            transform: translate3d(0, -33%, 0);

            &:first-child {
                left: 1.8rem;
            }

            &:last-child {
                right: 1.8rem;
            }
        }
    }

    &.products {
        --slide-spacing: 1rem;
        --slide-offset: 0;
    }

    &.recipeProducts {
        --slide-spacing: 1rem;
    }

    &.blockJams {
        --slide-spacing: 1rem;
        --item-width: calc(100vw - (2 * 2.4rem));

        article {
            height: 100%;
        }

        section > div:last-child > button {
            top: 33%;
            transform: translate3d(0, -33%, 0);

            &:first-child {
                left: 1.8rem;
            }

            &:last-child {
                right: 1.8rem;
            }
        }
    }

    &.holiday {
        --slide-spacing: 1rem;

        article {
            border: 0.2rem solid var(--colors-holiday-blue-dark);
            border-radius: 2rem;
            background-color: var(--colors-holiday-blue-medium);
        }
    }
}

.carousel {
    @include carousel-scrollbar-offset(
        var(--slide-offset),
        var(--slide-offset)
    );
}

.slide {
    @include carousel-gutters(var(--slide-offset));
}

.item {
    display: flex;
    width: var(--item-width, 75vw);
    max-width: calc(var(--block-max-width) / 3);
    height: 100%;
    flex-direction: column;
    padding: 0;
}

.threeUpFull {
    .slide {
        @include carousel-gutters(0);
    }

    .item {
        padding: 0;
    }
}

.cardSmall {
    color: var(--colors-blue);
}

.inner {
    flex: 1;
}

.recipeProducts.centered {
    --track-justify-content: center;
}

.inlineIntro {
    --slide-gap-color: var(--slider-gap-color);
}

@include smallOnly {
    .blockJams {
        --slide-offset: 2.4rem;
    }

    .inlineIntro {
        .slide {
            &:first-child {
                display: none;
            }

            &:nth-child(2) > * {
                padding-left: 0;
                margin-left: var(--slide-offset);
            }
        }
    }
}

@include medium {
    .root {
        --slide-offset: var(--block-padding-side);

        &.recipes,
        &.blockJams {
            section > div:last-child > button {
                top: 25%;
                transform: translate3d(0, -25%, 0);
            }
        }

        &.inlineIntro {
            pointer-events: none;
        }
    }

    .centered {
        --track-justify-content: center;
    }

    .item {
        --item-width: calc(((100vw - ((var(--block-padding-side) * 2))) / 3));

        min-width: 28rem;
    }

    .recipeProducts {
        .item {
            --item-width: calc(
                ((100vw - ((var(--block-padding-side) * 2))) / 3) - 1.8rem
            );
        }

        &:not(.centered) {
            --track-justify-content: left;
        }
    }

    .threeUpFull {
        .item {
            --item-width: calc(100vw / 3);
        }
    }
}

@include large {
    .root {
        &.recipes,
        &.blockJams {
            --slide-spacing: 1.2rem;

            section > div:last-child > button {
                top: 33%;
                transform: translate3d(0, -33%, 0);
            }
        }

        &.recipeProducts {
            --slide-offset: 0;
        }

        &.products,
        &.recipeProducts {
            --slide-spacing: 1.2rem;
        }

        &.holiday {
            article {
                border: 0.4rem solid var(--colors-holiday-blue-dark);
            }
        }
    }

    .threeUpFull,
    .threeUpContained:not(.products, .recipeProducts) {
        .slide {
            @include carousel-gutters(0);

            width: 33.333%;
        }

        .item {
            --item-width: 100%;

            max-width: none;
        }
    }

    .threeUpContained:not(.products, .recipeProducts) {
        max-width: var(--block-max-width-plus-padding);
        padding-right: var(--block-padding-side);
        padding-left: var(--block-padding-side);
        margin: 0 auto;
    }
}

@include xLarge {
    .recipeProducts {
        .item {
            max-width: 45.9rem;
        }
    }
}

@include blockMaxWidthPlusPadding {
    .root {
        --slide-offset: calc((100vw - var(--block-max-width)) / 2);
    }
}
