@import 'styles/breakpoints';

.stewardshipPage {
    .title {
        margin: 20px 0;
    }

    .link {
        margin-bottom: 10px;
    }
}

.commitmentCardsFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.commitmentCardsWrap {
    display: flex;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 var(--block-padding-side);
}

.commitmentCards {
    display: flex;
    flex-direction: var(--commitment-cards-flex-direction, column);
    padding: 0;
    margin-bottom: 0;
    list-style: none;
}

.card {
    width: 100%;
    margin-bottom: 1.2rem;
}

@include medium {
    .commitmentCards {
        --commitment-cards-flex-direction: row;

        flex-wrap: wrap;
        margin-right: -1.2rem;
    }

    .card {
        width: 50%;
        flex: 1 0 50%;
        padding-right: 1.2rem;
    }
}

@include large {
    .card {
        width: 33.333%;
        flex: 1 0 33.333%;
        padding-right: 1.2rem;
    }
}
