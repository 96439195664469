@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/animations';

.root {
    --flag-dimension: 8rem;

    width: 100%;
    height: 100%;
}

.inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 2.4rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    transform: translateY(0);
    transition: transform $easeOutCubic $fast;

    @include rounded-corners;

    .productCard & {
        gap: 2rem;
    }
}

.image {
    position: relative;

    .productCard & {
        img {
            width: 100%;
            margin: 10%;
        }

        picture {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2rem;
            aspect-ratio: 1.29;
            background-color: var(--colors-cream);
        }
    }

    img {
        overflow: hidden;
        border-radius: 2rem;
        aspect-ratio: 1.29;
        object-fit: contain;
        transition: transform $slow $easeOutCubic;
    }
}

.flag {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--flag-dimension);
    height: var(--flag-dimension);
    flex-direction: column;
    background-image: url('/images/cream-cheese-campaign/date-flag.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    border-top-left-radius: 2rem;
    color: var(--colors-blue);
    text-transform: uppercase;

    @include flex-center;

    p {
        margin-bottom: 0;
    }
}

.month {
    text-transform: uppercase;
}

.content {
    height: 100%;
    flex-direction: column;
    margin-top: 2rem;
    text-align: center;

    @include flex-center;

    .productCard & {
        justify-content: space-between;
        margin-top: 0;
    }
}

.headline {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-transform: uppercase;

    .productCard & {
        max-width: 24rem;
        margin-bottom: 1.4rem;
        text-transform: none;
    }
}

.label {
    letter-spacing: 0.05rem;
    line-height: 130%;
    text-transform: none;
}

.paragraph {
    margin-bottom: 0;
}

.cta {
    margin-top: 2rem;
    text-align: center;
}

@include large {
    .root {
        --flag-dimension: 10rem;
    }

    .headline {
        margin-bottom: 1.2rem;
        font-size: 2.4rem;
        line-height: 2.4rem;

        .productCard & {
            max-width: 32rem;
            margin-bottom: 2rem;
        }
    }

    .label {
        font-size: 1.8rem;
        line-height: 2.34rem;
    }

    .inner {
        padding: 3.4rem 3rem;
    }
}
