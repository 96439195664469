@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.lastBlock {
        padding-bottom: var(--last-block-padding-bottom);
    }
}

.inner {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    max-height: 104rem;
}

.imageAspect {
    position: relative;
    width: 100%;
    padding-bottom: 197%;
    height: 0;
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.content {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    color: var(--colors-cream);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    padding-bottom: 8rem;
}

.eyebrow {
    margin-bottom: 1.2rem;
}

.eyebrowText {
    text-transform: uppercase;
    margin-bottom: 0;

    .makersReserve & {
        color: var(--colors-makersReserveGoldAlt);
    }
}

.headline {
    text-transform: uppercase;
    margin-bottom: 1.6rem;

    .makersReserve & {
        text-transform: unset;
    }
}

.line {
    display: block;
}

.copy {
    max-width: 24rem;
    margin-bottom: 0.3rem;
}

.cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@include medium {
    .inner {
        max-height: none;
    }

    .imageAspect {
        padding-bottom: 50%;
    }

    .content {
        width: 50%;
        height: 100%;
        padding-bottom: 0;
        justify-content: center;
    }

    .eyebrow {
        margin-bottom: 2.2rem;
    }

    .headline {
        margin-bottom: 1.9rem;
    }

    .copy {
        margin-bottom: 0.7rem;
    }
}

@include large {
    .copy {
        max-width: 35rem;
    }
}
