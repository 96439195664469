@import 'styles/breakpoints';
@import 'styles/mixins';

.blockTimeline {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 0;
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }
}

.eyebrow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--block-padding-end);
}

.timelineLine {
    position: absolute;
    top: 2rem;
    left: 0;
    display: flex;
    width: 19%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 6rem;
}

.timelineLineInner {
    width: 0.2rem;
    height: 100%;
    background-color: var(--colors-orange);

    &::after,
    &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: none;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        background-color: var(--colors-orange);
        content: '';
        transform: translateX(-50%);
    }

    &::after {
        top: 0;
        display: block;
    }
}

.year {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 19%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-cream);
}

.yearInner {
    position: absolute;
    top: -1.1rem;
    padding: 1.1rem 1.1rem 0.9rem;
    background-color: var(--colors-cream);
}

.items {
    position: relative;
    padding: 8rem 0;
}

.timelineItem {
    position: relative;
    width: 100%;
    padding: 0 4% 4.8rem 25%;
}

.column {
    max-width: 47rem;
}

.image {
    margin: 0 0 2rem;

    @include rounded-corners;
}

.headline {
    margin: 0 0 2rem;
}

@include large {
    .timelineLine {
        top: 0;
        width: 100%;
    }

    .year {
        top: auto;
        width: 100%;
        margin-top: -2.4rem;
    }

    .items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .timelineItem {
        display: flex;
        max-width: var(--block-max-width);
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .column {
        width: 50%;
        max-width: none;
        padding: 0 11.4rem 11.4rem;

        &:last-child {
            text-align: right;
        }
    }

    .text {
        width: 100%;
        max-width: 38.4rem;
    }
}
