@import 'styles/breakpoints';

.commitmentCardsFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 0;

    .textEyebrow {
        padding-bottom: var(--textEyebrow-padding-bottom, 5rem);
        color: var(--colors-blue);
    }
}

.commitmentCardsWrap {
    display: flex;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 var(--block-padding-side);
}

.commitmentCards {
    display: flex;
    flex-direction: var(--commitment-cards-flex-direction, column);
    padding: 0;
    list-style: none;
}

.card {
    width: 100%;
    margin-bottom: 1.2rem;
}

@include medium {
    .commitmentCardsFlex {
        --textEyebrow-padding-bottom: 10rem;
    }
}

@include large {
    .commitmentCards {
        --commitment-cards-flex-direction: row;

        flex-wrap: wrap;
        margin-right: -1.2rem;
    }

    .card {
        flex: 1 0 33.333%;
        padding-right: 1.2rem;
    }
}
