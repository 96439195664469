@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
    max-width: 62.5rem;
    padding: var(--block-padding-end) 2.25rem;
    border-radius: 3rem;
    margin: var(--block-padding-end) auto;
    background-color: var(--colors-white);
    color: var(--colors-blue);
}

.header {
    display: flex;
    flex-direction: column;
    padding: 0 0 var(--block-padding-end);
    text-align: center;

    p {
        margin: 0;
    }
}

.eyebrow,
.headline {
    text-transform: uppercase;
}

.eyebrow {
    padding-bottom: 2rem;
}

.headline {
    padding-bottom: 2.4rem;
}

@include large {
    .root {
        max-width: 81.6rem;
        padding: var(--block-padding);
    }

    .header {
        p {
            padding: 0 5.35rem;
            margin: 0;
        }
    }
}
