@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

.downloadCard {
    background-color: var(--colors-white);
    color: var(--colors-blue);
    position: relative;
    height: 100%;

    @include rounded-corners;
}

.downloadCardInner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.lockUp {
    padding: var(--downloadCardInner-padding, 4.8rem 0);
}

.headline {
    text-transform: capitalize;
}

.ctaWrapper {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
}

.imageWrapper {
    position: relative;
    width: 100%;
    padding-top: var(--imageWrapper-padding-top, 53%);
    height: 0;
    overflow: hidden;
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform $slow $easeOutCubic;
}

@include medium {
    .downloadCard {
        --downloadCardInner-padding: 3rem 0;
        --imageWrapper-padding-top: 59%;
    }
}
