@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/fonts';

.directions {
    color: var(--colors-blue);
}

// overrides
.directionsHeadline {
    padding: 4rem 3rem 0;
    background-color: var(--colors-white);

    @include rounded-corners($radius: 2rem 2rem 0 0);

    &:not(:first-of-type) {
        margin-top: 1.4rem;
    }
}

.directionsName {
    padding: var(--directions-name-padding, 4.8rem 2rem 0);
    margin-top: var(--directions-name-margin-top, 2rem);
    background-color: var(--colors-white);

    &:first-of-type {
        --directions-name-margin-top: 0;
    }
}

.list {
    width: 100%;
    padding: 2.4rem 3rem 4rem;
    margin: 0;
    background-color: var(--colors-white);
    counter-reset: listCount;
    list-style: none;

    @include rounded-corners($radius: 0 0 2rem 2rem);

    &:first-child {
        @include rounded-corners;

        padding-top: 4rem;
    }
}

.listItem {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.4rem 0;
    border-bottom: 0.1rem solid rgba(#001e60, 0.1);
    margin: 0;

    &.hasTillaTip {
        flex-wrap: wrap;
    }

    .counter {
        margin: 0 0 1rem;
        counter-increment: listCount;

        &::before {
            @include flex-center;

            width: 2.5rem;
            min-width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: var(--colors-blue);
            color: var(--colors-white);
            content: counter(listCount);
            letter-spacing: 0;
        }
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    .list {
        padding: 2.4rem 2rem;
        margin-top: 2.4rem;
        background-color: var(--colors-cream);

        @include rounded-corners;

        .counter {
            @include flex-center;

            width: 3.8rem;
            height: 3.8rem;
            flex: 0 0 3.8rem;
            border-radius: 50%;
            background-color: var(--colors-white);

            svg {
                width: 2.5rem;
                height: 2.5rem;
            }

            &::before {
                display: none;
            }
        }

        .paragraph {
            font-size: 1.4rem;

            b {
                font-family: $GTWalsheimCond;
                letter-spacing: 0.15rem;
                line-height: 1.2;
            }
        }
    }
}

.fraction {
    margin-right: 0.4rem;
}

.paragraph {
    margin: 0;
    text-wrap: initial;
}

@include large {
    .directions {
        flex: 2;
    }

    .directionsHeadline {
        padding: 4rem 3rem 0;

        &:not(:first-of-type) {
            margin-top: 2.4rem;
        }
    }

    .directionsName {
        --directions-name-padding: 4.8rem 4.8rem 0;
    }

    .list {
        padding: 2.4rem 3rem 6rem;
    }

    .listItem {
        flex-direction: row;
        align-items: center;

        .counter {
            margin: 0 3rem 0 0;
        }

        .list {
            margin-left: 5.5rem;

            .counter {
                margin: 0 1.4rem 0 0;
            }

            .paragraph {
                max-width: none;
            }
        }
    }

    .paragraph {
        max-width: 90%;
    }
}

@include print {
    .directions {
        break-inside: avoid;
        page-break-inside: avoid;
    }
}
