@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/animations';

.root {
    position: relative;
    width: 100%;
    padding: 0 2.35rem;
    text-wrap: wrap;
}

.cta {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(color('blue'), 0.25);
}

.ctaText {
    padding: 1.4rem 0;
}

.linkListItem {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    border-bottom: 1px solid rgba(color('blue'), 0.25);
    gap: 2.4rem;

    @media (hover: hover) {
        &:hover {
            .circleButton {
                background-color: rgba(color('blueOcean'), 1);
                transition: background-color $easeOutQuad $fast;
            }
        }
    }

    > .linkListIcon,
    > .circleButton {
        display: none;
    }
}

.linkListIcon {
    position: relative;
    display: block;

    > svg {
        width: 4rem;
        height: 4rem;
    }
}

.circleButton {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    transition: background-color $easeOutQuad $fast;
}

.linkListText {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.linkListHeadline {
    width: 100%;
    margin: 0;
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
}

.linkListHeadlineContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;
}

.linkListBody {
    margin: 0;
    text-wrap: wrap;
}

@include medium {
    .root {
        padding: 0;
    }

    .circleButton {
        width: 2.8rem;
        height: 2.8rem;
    }

    .linkListItem {
        > .circleButton,
        .linkListIcon {
            display: flex;
        }
    }

    .linkListIcon {
        > svg {
            width: 4.6rem;
            height: 4.6rem;
        }
    }

    .linkListText {
        gap: 0.4rem;
    }

    .linkListHeadlineContainer {
        > .linkListIcon,
        > .circleButton {
            display: none;
        }
    }
}
