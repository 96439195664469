@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

@keyframes scale-in-check {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}

.productPage {
    overflow: hidden;
    flex-direction: column;
}

.header {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    color: var(--colors-blue);

    // Account for color-filled space above product image container
    &::before {
        display: block;
        width: 100%;
        height: var(--header-top-height, 10.8rem);
        background-color: var(--product-color);
        content: '';
    }
}

.topSeller {
    position: absolute;
    z-index: 5;
    top: 6rem;
    right: var(--block-padding-side);
    left: auto;
    width: var(--top-seller-width, 4.7rem);
    height: var(--top-seller-height, 6rem);
}

.headerInner {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -1px; // Account for seam between divs

    // Account for 2/3 of color-filled header IMAGE container
    &::after {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(66.7%);
        background-color: var(--product-color);
        content: '';
    }
}

.productImageWrapper {
    z-index: 2;
    width: 100%;
    max-width: min(var(--product-image-max-width, 60rem), 80vw);
    height: 100%;
    max-height: max(50rem, 60vh);
}

.aspectBox {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
}

.productImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: max(50rem, 60vh);
    object-fit: contain;
}

.headerContent {
    --content-spacer: 3.6rem;

    display: flex;
    width: 100%;
    max-width: 100rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: var(--header-content-padding-top, 2.4rem);
    color: inherit;
    text-align: center;

    @include smallOnly {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}

.eyebrow {
    padding-bottom: var(--group-label-padding-bottom, 1.2rem);
    text-transform: uppercase;
}

.title {
    text-transform: uppercase;
}

.weights {
    padding-top: var(--content-spacer);
}

.productDescriptionWrapper {
    max-width: 60rem;
    padding-top: var(--content-spacer);
}

.ctaButtons {
    display: flex;
    flex-direction: var(--cta-buttons-flex-direction, column);
    padding-top: var(--content-spacer);
}

.ctaButton {
    &:nth-child(2) {
        margin-top: 1.2rem;
    }
}

.productGuideDownload {
    margin-left: var(--button-spacer);
}

.productVariantsContainer {
    display: flex;
    width: 100%;
    margin-top: var(--block-padding-end);
    background-color: var(--colors-white);
    overflow-x: auto;
}

.productVariants {
    display: flex;
    align-items: center;
    justify-content: var(--productVariants-justify-content);
    padding: 0 4.8rem 0 2.4rem;
    margin: 0;
    list-style: none;

    > li {
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        margin: 3.4rem 1.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.productVariant {
    $block: &;

    position: relative;
    display: flex;
    width: 15rem;
    height: 15rem;
    flex-direction: column;
    align-items: center;
    padding-top: 2.3rem;
    border-radius: 50%;
    color: var(--colors-blueDark);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color $slow;

    &:hover,
    &:active {
        background-color: rgba(color('cream'), 0.5);
    }

    &__title {
        width: 11rem;
    }

    &--selected {
        background-color: var(--colors-cream) !important;

        .productVariantIconChecked {
            position: absolute;
            top: 0.8rem;
            right: 0.8rem;
            display: block;
            width: 2.8rem;
            height: 2.8rem;
            animation: scale-in-check $default $easeInOutCubic;
        }
    }

    picture {
        width: 8.8rem;
        height: 7.6rem;
        padding-bottom: 0.5rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.content {
    max-width: var(--block-max-width-plus-padding);
    padding: 0 var(--block-padding-side);
    margin: var(--block-padding-end) auto 0;
}

.contentInner {
    @include rounded-corners($overflow: hidden);
}

// Matches accordion height/width
.filledLabel {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 0 auto;
    background-color: var(--colors-blue);
    color: var(--colors-cream);
    text-transform: uppercase;
}

@include medium {
    .productPage {
        --productVariants-justify-content: center;
        --cta-buttons-flex-direction: row;
        --cta-button-display: flex;
        --product-image-max-width: 80rem;
    }

    .ctaButton {
        &:nth-child(2) {
            margin-top: 0;
            margin-left: 1.2rem;
        }
    }
}

@include large {
    .productPage {
        --header-top-height: 13.2rem;
        --header-content-padding-top: 4.8rem;
        --group-label-padding-bottom: 2.4rem;
        --top-seller-width: 6rem;
        --top-seller-height: 7.6rem;
    }

    .ctaButton {
        &:nth-child(2) {
            margin-left: 2.4rem;
        }
    }

    .productVariantsContainer {
        justify-content: center;
    }

    .productVariants {
        padding: 0 4.8rem;
    }
}

@include xxLarge {
    .productPage {
        --header-content-padding-top: 6rem;
        --header-top-height: 14.2rem;
    }
}
