@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

.breadcrumbs {
    display: flex;
    overflow: hidden;
    width: auto;
    padding: 1.65rem var(--grid-padding-side) 1.7rem var(--grid-padding-side);
    background: var(--colors-cream);
    color: var(--colors-blue);
    font-family: $GTWalsheimCond;
    font-size: 1.4rem;
    letter-spacing: 2px;
    line-height: 120%;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    a {
        text-decoration: none;

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__carrot {
        position: relative;
        display: var(--breadcrumbs-carrot-display, inline-block);
        width: 0.7rem;
        height: 1.1rem;
        margin-right: 1rem;
    }

    &__separator {
        display: var(--breadcrumbs-separator-display, none);
        margin: 0 1rem;
        font-size: 1.6rem;
        font-weight: bold;
    }

    &__currentPage {
        display: var(--breadcrumbs-currentPage-display, none);
        font-weight: bold;
    }

    &__currentCategory {
        position: relative;
        font-weight: var(--breadcrumbs-currentCategory-font-weight, bold);
    }

    &__root {
        display: var(--breadcrumbs-root-display, none);
    }
}

@include medium {
    .breadcrumbs {
        --breadcrumbs-separator-display: inline-block;
        --breadcrumbs-currentPage-display: inline-block;
        --breadcrumbs-root-display: inline-block;
        --breadcrumbs-currentCategory-font-weight: normal;
        --breadcrumbs-carrot-display: none;
    }
}
