@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.2rem;
    color: var(--text-color, var(--colors-blue));
    gap: 1.2rem;

    &.isPlaceholder {
        visibility: hidden;
    }
}

.headline,
.eyebrow {
    text-transform: uppercase;
}

.eyebrow {
    margin: 0;
}

.copy {
    margin-bottom: 1.2rem;
}

@include large {
    .root {
        margin-right: 2.4rem;
        gap: 2.4rem;
    }

    .copy {
        margin-bottom: 2.4rem;
    }
}

@include xLarge {
    .root {
        margin-right: 4.8rem;
    }
}
