@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.videoOverlay {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: var(--videoOverlay-padding, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    &.isOpen {
        opacity: 1;
        pointer-events: auto;
        transition: opacity $easeOutQuint $slow, visibility $easeOutQuint $slow;
        visibility: visible;
    }
}

.videoOverlayBackdrop,
.videoOverlay,
.iFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoOverlayBackdrop {
    background-color: rgba(0, 0, 0, 80%);

    &.transparent {
        background-color: transparent;
    }
}

.videoOverlayClose {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 50%;
    background-color: var(--colors-cream);
    color: var(--colors-blue);
}

.videoOverlayInnerWrap {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    padding: var(--block-padding);

    &.tiktokOverlay {
        width: auto;
        max-width: none;
        height: 100%;
        padding: 0;
    }
}

.videoOverlayInner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: var(--colors-cheeseBlackPepper);
    opacity: 0;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    .isOpen & {
        opacity: 1;
        transition: opacity $easeOutQuint $superSlow * 2 0.2s,
            visibility $easeOutQuint $superSlow 0.2s;
        visibility: visible;
    }

    .tiktokOverlay & {
        height: auto;
        padding-bottom: 0;

        @include center-y;

        iframe {
            margin: 0 auto;
        }

        iframe,
        video {
            max-height: 70vh !important;
        }
    }
}
