@import 'styles/breakpoints';

.richTextBlock {
    display: flex;
    max-width: 102.4rem;
    flex-direction: column;
    justify-content: center;
    padding: var(--block-padding, 4.8rem 2.4rem);
    margin-right: auto;
    margin-left: auto;
    color: var(--colors-blue);
    word-break: break-word;

    table {
        overflow: hidden;
        padding: 0;
        border: 1px solid var(--colors-blue);
        border-radius: 2rem;
        margin-bottom: 2em;
        background: white;
        border-collapse: separate;
        border-spacing: 0;

        th,
        td {
            padding: 2rem 2rem 0;
            border-right: 1px solid var(--colors-blue);
            border-bottom: 1px solid var(--colors-blue);
            margin: 0;

            &:last-child {
                border-right: none;
            }
        }

        th {
            background-color: #badaf3;
            text-align: left;

            p {
                font-weight: bold;
            }
        }

        tr:last-child td,
        tr:last-child th {
            border-bottom: none;
        }
    }

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.bodyText {
    margin-bottom: 2rem;
}

.link {
    text-decoration: underline;
}

@include large {
    .richTextBlock {
        --block-padding: 9.6rem 20.4rem;
    }
}
