@import 'styles/breakpoints';
@import 'styles/mixins';

.cardLatest {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include rounded-corners($overflow: hidden);

    &.withBackground {
        position: relative;

        .inner {
            justify-content: center;
        }

        &::before,
        &::after {
            position: absolute;
            content: '';
            background-color: var(--colors-holiday-blue-dark);
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 10rem;
        }

        &::before {
            background-image: url('/images/campaigns/holiday-23/holiday-border-top.svg');
            background-position-y: 0.5rem;
            top: 0;
        }

        &::after {
            background-image: url('/images/campaigns/holiday-23/holiday-border-bottom.svg');
            background-position-y: -0.5rem;
            bottom: 0;
        }
    }
}

.cardLinkWrap {
    width: 100%;
}

.inner {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    padding: var(--inner-padding, 8vw 3.6rem);
}

.imageContainer {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}

.eyebrow {
    text-transform: uppercase;
}

.headline {
    margin: 1rem 0 0;
    max-width: var(--headline-max-width, 22rem);
}

.splitHeadline {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;

    span:first-child,
    span:last-child {
        @include block-text(var(--colors-cream));
    }

    span:nth-child(2) {
        font-size: 2.4rem;
        line-height: 1.2;
    }
}

@include medium {
    .cardLatest {
        --inner-padding: 5rem 3.2rem;
        --headline-max-width: 34.9rem;
    }
}

@include large {
    .splitHeadline {
        span:nth-child(2) {
            font-size: 4rem;
        }
    }
}

@include xxLarge {
    .cardLatest {
        --inner-padding: 6.2rem;
    }

    .headline {
        max-width: none;
    }
}

@include smallOnly {
    .Holiday {
        .headline {
            font-size: clamp(1.4rem, 0.0667rem + 4.4444vw, 1.8rem);
        }
    }
}
