@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/animations';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 2.4rem;

    .cta {
        border-radius: 10rem;
    }
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    text-align: center;

    > span {
        position: relative;
        width: 100%;
        height: 100%;
    }

    @media (hover: hover) {
        &:hover {
            .heroImgBlock {
                transform: scale(1.02);
            }
        }
    }
}

.eyebrowBlock {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--block-padding-end);
    color: var(--colors-blue);
}

.contentWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 0.2rem solid var(--colors-blue);
    color: var(--colors-cream);

    @include rounded-corners($overflow: hidden);
}

.innerWrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: var(--innerWrapper-width, 100%);
    height: var(--innerWrapper-height, 53%);
    align-items: center;
    justify-content: center;
}

.innerWrapperInner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.textBlock {
    z-index: 10;
    top: 0;
    left: var(--textBlock-left, 0);
    display: flex;
    width: 100%;
    max-width: var(--textBlock-max-width, 32rem);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colors-cream);
}

.headlineWrapper {
    margin-bottom: 2rem;
    text-align: center;

    .headline {
        max-width: 26rem;
        color: var(--colors-cream);
        text-transform: uppercase;
    }
}

.eyebrowWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.heroImgBlock {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 194%;
    transition: transform $slow $easeOutCubic;

    .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: none;
        height: 100%;
        object-fit: cover;
    }
}

.innerEyebrow {
    h3 {
        text-transform: none;
    }
}

.eyebrow {
    margin-bottom: 1rem;
    color: var(--colors-cream);
    text-transform: uppercase;
}

.buttonWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@include smallOnly {
    .heroImgBlock {
        padding-bottom: 166%;
    }
}

@include medium {
    .root {
        padding: var(--block-padding);
    }

    .inner {
        --innerWrapper-height: 100%;
        --innerWrapper-width: 50%;
    }

    .heroImgBlock {
        padding-bottom: 56.25%;
    }
}

@include large {
    .inner {
        --textBlock-max-width: 41rem;
    }

    .heroImgBlock {
        padding-bottom: 50%;
    }

    .contentWrapper {
        border-width: 0.4rem;
    }
}

@include xLarge {
    .inner {
        --textBlock-max-width: 45rem;
    }
}
