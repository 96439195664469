@import 'styles/breakpoints';

.root {
    width: 100%;
    max-width: var(--block-max-width);
    margin: 0 auto;
}

.list {
    display: flex;
    flex-flow: var(--list-flex-flow, column wrap);
    margin: 0;

    &::after {
        display: block;
        width: 100%;
        height: 0.1rem;
        background: rgba(0, 30, 96, 10%);
        content: '';
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.group {
    display: flex;
    flex-direction: var(--group-flex-direction, row);
    background-color: var(--colors-white);
    color: var(--colors-blueDark);

    dt {
        display: flex;
        height: 4.8rem;
        flex: 1;
        flex-basis: 40%;
        align-items: center;
        justify-content: flex-start;
        padding: 0 1rem 0 1.8rem;
        background: rgba(0, 30, 96, 10%);
        text-transform: uppercase;
    }

    dd {
        display: flex;
        height: 4.8rem;
        flex: 2;
        flex-basis: 60%;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0 0 2.4rem;
        margin: 0;
    }
}

@include large {
    .list {
        --list-flex-flow: row wrap;
        --group-flex-direction: column;

        &:nth-child(n + 2) {
            .group {
                dt {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }

    .group {
        width: calc(100% / var(--product-list-columns));

        dt {
            height: auto;
            padding: 1.6rem 0 1.6rem 2.4rem;
        }

        dd {
            height: auto;
            padding: 1.6rem 1rem 1.6rem 2.4rem;
        }
    }
}
