@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

@keyframes fade-in {
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}

.card {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: var(--card-background-color);

    @include rounded-corners;

    &.theme--makers-reserve {
        border-radius: 0;
    }
}

.cardLinkWrap {
    width: 100%;
}

.iconPlacement {
    position: absolute;
    z-index: 10;
    top: 0;
    right: var(--icon-right-position, 4rem);
    color: var(--colors-cream);
}

.icon {
    position: relative;
    width: 6rem;
    height: 6rem;

    > span {
        width: 100%;
        height: 100%;
    }
}

@include smallOnly {
    .iconPlacement {
        --icon-right-position: 2rem;

        .icon {
            width: 4rem;
            height: 4rem;
        }
    }

    .textGroup .eyebrow {
        // linearly interpolate between 0.8rem and 1.4rem based on viewport width
        font-size: clamp(0.8rem, 0.2976rem + 1.4354vw, 1.4rem);

        // linearly interpolate between 0.8rem and 1.2rem based on viewport width
        .theme--makers-reserve & {
            font-size: clamp(0.8rem, 0.4651rem + 0.9569vw, 1.2rem);
        }
    }

    .textGroup .cta {
        // linearly interpolate between 1.2rem and 2.4rem based on viewport width
        font-size: clamp(1.2rem, 0.1952rem + 2.8708vw, 2.4rem);

        .theme--makers-reserve & {
            // linearly interpolate between 1.2rem and 1.8rem based on viewport width
            font-size: clamp(1.2rem, 0.6976rem + 1.4354vw, 1.8rem);
        }
    }
}

@mixin card-hover {
    background-color: transparent;

    img {
        transform: translateY(-1.2rem);
    }

    .cta,
    .eyebrow {
        color: var(--colors-cream);
    }

    &--dark-text {
        .cta,
        .eyebrow {
            color: var(--colors-blue);
        }
    }
}

@mixin card-hover-dark {
    background-color: transparent;

    .cta,
    .eyebrow {
        color: var(--colors-blue);
    }
}

@mixin card-hover-makers {
    background-color: transparent;

    .cta,
    .eyebrow {
        color: var(--colors-makersReserveBlack);
    }
}

.cardInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: var(--colors-white);
    transition-duration: $fast;
    transition-property: background, color;

    @media (hover: hover) {
        &:hover {
            @include card-hover;

            .award {
                opacity: 1;
            }
        }
    }

    &:active {
        @include card-hover;
    }

    &--dark-text {
        @media (hover: hover) {
            &:hover {
                @include card-hover-dark;
            }
        }

        &:active {
            @include card-hover-dark;
        }
    }

    .theme--makers-reserve & {
        background-color: var(--colors-cream);

        @media (hover: hover) {
            &:hover {
                @include card-hover-makers;
            }
        }

        &:active {
            @include card-hover-makers;
        }
    }
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--cardContent-padding, 2rem 1.6rem 5vw);
}

.imageContainer {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    picture {
        width: 100%;
        height: auto;
    }

    &--portrait {
        picture {
            width: auto;
            height: 100%;
        }
    }
}

.award {
    position: absolute;
    bottom: 100%;
    max-width: 20rem;
    opacity: 0;
}

.image {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .theme--makers-reserve & {
        top: auto;
        bottom: 2%;
    }

    > div {
        width: 100%;
        height: 100%;
    }

    &.isVisible picture img {
        animation: fade-in $default $easeOutCubic both;
        animation-delay: $default;
        opacity: 1;
    }

    picture {
        position: relative;

        img {
            position: relative;
            z-index: 2;
            opacity: 0;
            transition: transform $slow $easeOutQuad;
        }

        &::after {
            position: absolute;
            z-index: 1;
            bottom: 0%;
            left: 50%;
            display: block;
            width: calc(var(--card-width, 140%) * 1.45);
            padding-top: calc(var(--card-width, 140%) * 0.7);
            content: '';
            transform: translateY(35%) translateX(-50%);
        }
    }
}

.eyebrow {
    margin-bottom: 0.6rem;
    text-transform: uppercase;
}

.textGroup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 1.2rem;
}

.cta,
.eyebrow {
    color: var(--colors-blue);
    text-align: center;

    .theme--makers-reserve & {
        color: var(--colors-makersReserveBlack);
    }
}

@include medium {
    .cardContent {
        --cardContent-padding: 2.4rem 2.4rem 5rem;
    }

    .eyebrow {
        margin-bottom: 1rem;
    }

    .award {
        max-width: 27rem;
    }

    .textGroup {
        padding-top: 3.6rem;
    }
}

@include large {
    .cardContent {
        --cardContent-padding: 5.6rem 3.6rem;
    }
}

@include xxxLarge {
    .cardContent {
        --cardContent-padding: 5.6rem 4.6rem;
    }
}
