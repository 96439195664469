@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    --slide-spacing: 1rem;
    --slide-offset: var(--block-padding-side);

    padding: var(--block-padding-end) 0;
    overflow-x: hidden;

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.carousel {
    @include carousel-scrollbar-offset(
        var(--slide-offset),
        var(--slide-offset)
    );
}

.slide {
    @include carousel-gutters(var(--slide-offset));
}

.item {
    position: relative;
    width: 75vw;
    border: none;
    margin: 0;
    background: transparent;
}

@include medium {
    .root {
        --slide-spacing: 4.8rem;
    }
}

@include blockMaxWidthPlusPadding {
    .root {
        --slide-offset: calc((100vw - var(--block-max-width)) / 2);
    }
}
