@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

@mixin card {
    --end-card-flex-direction: column;
    --list-item-padding-top: 0;
    --end-card-text-padding: 2.4rem 0 4.8rem;

    .iconGroup {
        justify-content: center;
        padding-right: 0;
    }

    .contentGroup {
        width: auto;
        align-items: center;
    }

    .contentWrapper {
        align-items: center;
    }
}

@mixin landscape {
    --list-item-padding-top: 0;

    height: 30rem;
    min-height: 100%;

    .theme--makers-reserve & {
        --end-card-text-padding: 0 0 4.8rem;
        --end-card-flex-direction: row;

        .iconGroup {
            justify-content: flex-end;
        }

        .headlineWrapper {
            display: flex;
            width: 50%;
            justify-content: center;
        }

        .headline {
            --headline-width: 17rem;
            --headline-max-width: 17rem;
        }

        .contentGroup {
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
    }
}

.root {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--root-padding, 2.4rem);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

@include last-filler-item(2);

.eyebrow {
    width: 100%;
    padding: var(--eyebrow-padding, 0 0 4.8rem);
}

.productList {
    display: grid;
    overflow: hidden;
    width: 100%;
    max-width: var(--block-max-width);
    padding: 0;
    margin: 0 auto;
    grid-auto-columns: 1fr;
    grid-gap: var(--gridGap, 1rem);
    grid-template-areas: var(--gridColumnAreas, 'a a');
    list-style: none;
}

.listItem {
    box-sizing: border-box;

    &:last-child {
        position: relative;

        // consistent with product card on mobile
        padding-top: var(--list-item-padding-top, 131%);
    }

    .theme--makers-reserve & {
        .headline {
            width: var(--headline-width, 17rem);
            max-width: var(--headline-max-width, 17rem);
        }
    }
}

.endCardContent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: var(--end-card-flex-direction, column);
    align-items: center;
    justify-content: center;
    padding: var(--end-card-padding, 4rem 3rem);
    text-align: center;
}

.iconGroup {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: var(--icon-group-padding-right, 0);
}

.iconWrapper {
    width: var(--icon-wrapper-width, 4.8rem);
    max-width: var(--icon-wrapper-max-width, 4.8rem);
}

.endCardText {
    position: relative;
    width: 100%;
    max-width: var(--end-card-text-max-width, 27rem);
    padding: var(--end-card-text-padding, 2.5rem 0 4.8rem);
    margin-bottom: 0;

    .theme--makers-reserve & {
        &::after {
            position: absolute;
            bottom: 2.4rem;
            left: 50%;
            display: block;
            width: 2.4rem;
            height: 0.2rem;
            background-color: var(--colors-makersReserveGoldAlt);
            content: '';
            transform: translate(-50%, -50%);
        }
    }

    // Override rich text
    p {
        margin: 0;
    }
}

.contentGroup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ctaButton {
    margin: 0 auto;
}

.makersReserveBorder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.4rem;
}

.makersReserveBorderInner {
    position: relative;
    width: 100%;
    height: 100%;
}

@include medium {
    .root {
        --root-padding: 4.8rem;

        .theme--makers-reserve & {
            --end-card-text-max-width: 70%;
        }
    }

    .productList {
        --gridGap: 1.2rem;
    }

    // Double (Landscape) [xx]
    .listItem:last-child:nth-child(2n-1) {
        --end-card-text-max-width: 32rem;

        @include landscape;

        height: 30rem;
    }
}

@include large {
    .root {
        --end-card-flex-direction: column;
        --root-padding: var(--block-padding-side);
        --eyebrow-padding: 0 0 9.6rem;
        --gridColumnAreas: 'a a a';
        --end-card-text-max-width: 43rem;

        .makersReserveBorder {
            padding: 2rem;
        }
    }

    @include last-filler-item(3);

    // Single Span [__x]
    .listItem:last-child:nth-child(3n) {
        --end-card-text-max-width: 23rem;

        @include card;

        .theme--makers-reserve & {
            --end-card-text-padding: 2.4rem 0 4.8rem;

            .headlineWrapper {
                width: 100%;
            }

            .headline {
                --headline-width: 22rem;
                --headline-max-width: 22rem;
            }

            .contentGroup {
                flex-direction: column;
            }
        }
    }

    // Double Span  [_xx]
    .listItem:last-child:nth-child(3n - 1) {
        --end-card-padding: 4rem;
        --icon-group-adding-right: 0;
        --end-card-text-max-width: 30rem;

        @include landscape;

        .iconGroup {
            justify-content: center;
        }

        .theme--makers-reserve & {
            .headline {
                --headline-width: 26rem;
                --headline-max-width: 26rem;
            }
        }
    }

    // Triple Span (Landscape) [xxx]
    .listItem:last-child:nth-child(3n - 2) {
        --end-card-text-max-width: 43rem;
        --content-wrapper-width: 43.3rem;

        @include landscape;

        height: 35rem;

        .theme--makers-reserve & {
            .headline {
                --headline-width: 26rem;
                --headline-max-width: 26rem;
            }
        }
    }
}

@include xLarge {
    .contentGroup {
        p:first-child {
            font-size: 2.4rem; // override only when no headline exists
        }
    }
}
