/* stylelint-disable max-nesting-depth */
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';
@import 'components/ui/AnimatedIcon/AnimatedIcon.module';

.root {
    position: relative;
    display: block;
    height: 100%;
    background-color: var(--background-color);
    color: var(--colors-blue);
    transition: translate $slow $bounce;

    &::before {
        position: absolute;
        bottom: -20rem;
        width: 100%;
        height: 20rem;
        background-color: var(--background-color);
        content: '';
    }

    @media (hover: hover) {
        &:hover {
            translate: 0 -3rem;

            .image {
                scale: 1.04;
            }
        }
    }
}

.inner {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.imageContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 2rem;
    margin-bottom: 2.4rem;
    aspect-ratio: 1/1;

    .isEven & {
        aspect-ratio: 4 / 3;
    }
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: scale $slow $bounce;
}

.headline {
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.headline,
.copy {
    padding: 0 1.2rem;
}
