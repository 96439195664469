@import 'styles/breakpoints';
@import 'styles/mixins';

.columnContent {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--columnContent-padding, 4.8rem 2.4rem 2.4rem);

    @include rounded-corners($breakpoint: large);

    &.creamBackground {
        background-color: var(--colors-cream);
    }
}

.columnContentInner {
    width: 100%;
    max-width: var(--columnContentInner-max-width, 37.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eyebrowWrapper {
    padding-bottom: var(--eyebrowWrapper-padding-bottom, 2.5rem);
    max-width: var(--eyebrowWrapper-max-width, 32.7rem);
}

.textWrapper {
    padding-bottom: var(--textWrapper-padding-bottom, 2rem);
    max-width: var(--textWrapper-max-width, none);
    text-align: center;
}

.imgWrapper {
    width: 100%;

    @include rounded-corners($overflow: hidden);

    .image {
        width: 100%;
    }
}

.buttonWrapper {
    padding-bottom: var(--buttonWrapper-padding-bottom, 4.8rem);
    width: var(--buttonWrapper-width, 100%);

    max-width: 43.2rem;

    > a > span,
    button {
        width: 100%;
    }

    &.ctaDuo {
        display: var(--buttonWrapper-ctaDuo-display, block);
        align-items: center;
        justify-content: center;
        flex-direction: flex;
        width: var(--buttonWrapper-ctaDuo-width, 100%);

        > a > span,
        button {
            width: var(--buttonWrapper-ctaDuo-span-width, 100%);
        }
    }
}

.cta2 {
    margin: var(--cta2-margin, 1.2rem 0 0);
}

@include large {
    .columnContent {
        --columnContent-padding: 10.8rem 3.6rem 3.6rem 3.6rem;
        --eyebrowWrapper-padding-bottom: 3.5rem;
        --eyebrowWrapper-max-width: 38.4rem;
        --textWrapper-max-width: 40rem;
        --buttonWrapper-padding-bottom: 7.2rem;
        --buttonWrapper-width: none;
    }
}

@include xxLarge {
    .columnContent {
        --columnContentInner-max-width: none;
        --buttonWrapper-ctaDuo-width: 100%;
        --buttonWrapper-ctaDuo-display: flex;
        --buttonWrapper-ctaDuo-span-width: auto;

        --cta2-margin: 0 0 0 2.4rem;
    }
}
