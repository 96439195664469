@import 'styles/breakpoints';

.blockTestimonial {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);
    margin-top: var(--block-padding-end);

    &.flushTop {
        margin-top: 0;
    }
}

.blockTestimonialInner {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blueBackground {
    background-color: var(--colors-blue);
}

.whiteBackground {
    background-color: var(--colors-white);
}
