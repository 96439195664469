@import 'styles/breakpoints';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 2rem calc(var(--block-padding-end) * 1.5);
    color: var(--colors-blue);
    text-align: center;
}

.list {
    padding: 0;
    list-style: none;
    text-align: center;
}
