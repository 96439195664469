@import 'styles/colors';
@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    --slide-offset: 2.4rem;
    --slide-spacing: 1.2rem;

    overflow: hidden;
    padding: var(--block-padding-end) 0;
    background-color: var(--colors-white);
    color: var(--colors-blue);
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;

    &::before,
    &::after {
        position: relative;
        width: 100%;
        height: 0.1rem;
        background-color: var(--colors-blue);
        content: '';
        opacity: 0.2;
    }
}

.jaggedTop {
    display: flex;
    flex-direction: column;
    color: var(--colors-white);
    transform: translateY(0.2rem);

    svg {
        width: 100%;
    }
}

.headline {
    margin: 0 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
}

.carousel {
    @include carousel-scrollbar-offset(
        var(--slide-offset),
        var(--slide-offset)
    );

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 3rem;
}

.slide {
    flex-shrink: 0;

    &:first-of-type > * {
        margin-left: var(--slide-offset);
    }

    &:last-of-type > * {
        margin-right: var(--slide-offset);
    }
}

.link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.1rem 2rem;
    border: 0.1rem solid rgba(color('blue'), 0.2);
    border-radius: 0.8rem;
    cursor: pointer;
    gap: 1.2rem;
    transition: background-color $fast $easeOutCubic, color $fast $easeOutCubic;

    @media (hover: hover) {
        &:hover {
            background-color: var(--colors-blue);
            color: var(--colors-cream);
            transition: background-color $fast $easeOutCubic,
                color $fast $easeOutCubic;
        }
    }
}

@include smallOnly {
    .headline {
        font-size: 1.8rem;
    }
}

@include medium {
    .root {
        --slide-offset: 4.8rem;
    }

    .header {
        padding: 0 4.8rem;
    }

    .headline {
        margin: 0 2.4rem;
    }

    .carousel {
        justify-content: center;
        padding-top: 6rem;
    }
}
