@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/colors';

.root {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: calc(2 * var(--block-padding-end)) 0 0; // additional padding to account for negative margin-bottom on above section
    color: var(--colors-blue);

    @include ncdTextureOrangeMedium;

    .cta {
        span {
            border-radius: 10rem;
        }

        @include block-jams-cta;
    }
}

.header {
    position: relative;
    display: flex;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.stickerOverlay {
    background-color: rgba(193, 225, 193, 100%);
}

.cta {
    margin-bottom: 2rem;
}

.headline {
    @include block-text(var(--colors-cream));

    font-size: 4.8rem;
    line-height: 0.85;
    text-align: center;
}

.eyebrow {
    font-size: 2.4rem;
    text-transform: uppercase;
}

.body {
    max-width: 39rem;
    margin: 2.4rem 1rem;
    text-align: center;
}

.sticker {
    --padding: 2rem;
    --sticker-width: 12.6rem;

    position: absolute;
    z-index: 5;
    top: 34rem;
    left: calc(100% - var(--sticker-width) - var(--padding));
}

@include medium {
    .root {
        padding-bottom: 4.8rem;
    }

    .cta {
        margin-bottom: 0;
    }

    .body {
        max-width: 68.4rem;
        margin-top: 2.4rem;
        margin-bottom: 4.8rem;
    }

    .sticker {
        --padding: 10%;
    }

    .eyebrow {
        font-size: 6rem;
    }
}

@include large {
    .headline {
        font-size: 12rem;
    }

    .sticker {
        --sticker-width: 18rem;
    }
}

@include xLarge {
    .sticker {
        top: 40rem;
    }
}

@include xxLarge {
    .sticker {
        top: 52rem;
    }
}

@include blockMaxWidthPlusPadding {
    .sticker {
        --padding: calc((((100% - var(--block-max-width)) / 2)) + 3rem);
    }
}
