@import 'styles/animations';

.buttonResets {
    display: inline;
    overflow: visible;
    padding: 0;
    border: 0;
    margin: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: normal;
    text-align: left;
}

.base {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: all $fast;
}

// global
.isLink {
    display: flex;

    &.disabled {
        pointer-events: none;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
