@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

.root {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
    padding: var(--block-padding-end) var(--block-padding-side);
    margin: 0 auto;
    color: var(--colors-blue);

    @include flex-center;

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.lastBlock {
        padding-bottom: var(--last-block-padding-bottom);
    }
}

.recipes {
    padding: 2.4rem 2.4rem 0;
}

.recipes,
.recipeDetail {
    max-width: var(--block-max-width-plus-padding);

    .column {
        aspect-ratio: 1;
        background-color: var(--colors-blue);
        color: var(--colors-cream);
    }

    .headline {
        margin-bottom: 2rem;
        text-transform: none;
    }

    .cta {
        margin-top: 3.6rem;
    }
}

.recipeDetail {
    --detail-padding-side: 0;

    padding: 0 var(--detail-padding-side) var(--block-padding-end);

    .inner {
        border-radius: 0;
    }

    .column {
        &:last-of-type {
            order: -1;
        }
    }
}

.inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: var(--colors-white);

    @include rounded-corners($overflow: hidden);
}

.column {
    position: relative;
    width: 100%;
    aspect-ratio: 0.88;

    &:last-child {
        flex-direction: column;
        padding: 3.6rem 2.4rem;
        text-align: center;

        @include flex-center;
    }
}

.lockup {
    display: flex;
    width: 100%;
    max-width: 43rem;
    flex-direction: column;
    align-items: center;
}

.eyebrow {
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.headline {
    margin-bottom: 2.4rem;
    text-transform: uppercase;
}

.copy {
    max-width: 33rem;
}

.buttonWrapper {
    margin-top: 2.4rem;
}

@include medium {
    .inner {
        flex-direction: row-reverse;

        .imageOnLeft & {
            flex-direction: row;
        }
    }

    .column {
        width: 50%;
    }

    .recipes,
    .recipeDetail {
        .eyebrow {
            margin-bottom: 2rem;
        }

        .buttonWrapper {
            margin-top: 4.8rem;
        }
    }

    .recipes {
        padding: 4.8rem var(--block-padding-side) 0;

        .headline {
            > h2 {
                font-size: 4.8rem;
            }
        }
    }

    .recipeDetail {
        --detail-padding-side: 2.4rem;

        .inner {
            @include rounded-corners($overflow: hidden);
        }

        .column {
            aspect-ratio: 0.88;

            &:last-of-type {
                order: 1;
            }
        }
    }
}

@include large {
    .recipeDetail {
        --detail-padding-side: 3rem;
    }
}

@include xLarge {
    .recipeDetail {
        --detail-padding-side: 4.8rem;
    }
}

@include print {
    .recipeDetail {
        .column {
            aspect-ratio: 0 !important;
        }

        .imageWrapper {
            display: none;
        }
    }
}
