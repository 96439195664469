@import 'styles/breakpoints';

.videoBlock {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.embeddedEntry {
        width: calc(100% + 2.4rem);
        padding: 4.8rem 0;
    }

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.insert {
        position: absolute;
        padding: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.inner {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: var(--block-max-width);
}

@include medium {
    .videoBlock {
        &.embeddedEntry {
            width: 100%;
        }

        &.flushTop {
            padding-top: 0;
        }

        &.flushBottom {
            padding-bottom: 0;
        }

        &.insert {
            padding: 0;
        }
    }
}
