@import 'styles/breakpoints';
@import 'styles/mixins';

.blockTwoColumnFlex {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.blockTwoColumn {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: var(--blockTwoColumn-flex-direction, column);
    color: var(--colors-blue);

    @include rounded-corners($overflow: hidden);

    .flushTop & {
        @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
    }

    .flushBottom & {
        @include rounded-corners($overflow: hidden, $radius: 2rem 2rem 0 0);
    }

    .flushTop.flushBottom & {
        border-radius: 0;
    }
}

.column {
    position: relative;
    width: var(--column-width, 100%);

    &:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: var(--column-last-justify-content, center);
        padding: var(--column-lastChild-padding, var(--block-padding-end));
        background-color: var(--colors-white);

        .theme--dark & {
            background-color: var(--colors-blue);
            color: var(--colors-white);
        }
    }
}

.image {
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.lockup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.iconWrapper {
    margin-bottom: 1.2rem;
}

.eyebrow {
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.headline {
    max-width: 48rem;
    text-align: center;
    text-transform: uppercase;
}

.richHeadline {
    text-align: center;
}

.contactHeadline {
    align-self: flex-start;
}

.secondaryHeadline {
    margin-top: var(--secondaryHeadline-margin-top, 3rem);
    text-transform: uppercase;

    &:first-of-type {
        margin-top: 0;
    }
}

.richParagraph {
    padding-top: var(--richParagraph-padding-top, 0.6rem);
}

.richUnorderedList {
    text-align: left;
}

.copy {
    margin: var(--copy-margin, 2rem 0 0);
    text-align: center;

    p {
        &:last-child {
            margin: 0;
        }
    }
}

.buttonWrapper {
    position: relative;
    display: flex;
    width: var(--buttonWrapper-width, 100%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--buttonWrapper-margin-top, 3.6rem);

    &.multiButton {
        .cta {
            &:first-child {
                margin-bottom: 1.2rem;
            }
        }
    }
}

.contactsText {
    padding-top: var(--contactsText-padding-top, 3rem);
}

@include medium {
    .blockTwoColumn {
        --blockTwoColumn-flex-direction: row-reverse;
        --column-width: 50%;
        --column-lastChild-padding: var(--block-padding-end) 4.8rem;
        --buttonWrapper-margin-top: 4.8rem;
        --buttonWrapper-width: auto;
        --copy-margin: 3rem 0 0;
        --contactsText-padding-top: 4rem;
        --secondaryHeadline-margin-top: 4rem;
        --richParagraph-padding-top: 0.8rem;
    }

    .blockTwoColumnReversed {
        --blockTwoColumn-flex-direction: row;
    }
}

@include large {
    .eyebrow,
    .iconWrapper {
        margin-bottom: 2rem;
    }

    .buttonWrapper {
        &.multiButton {
            flex-direction: row;

            .cta {
                width: auto;

                &:first-child {
                    margin-right: 1.2rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@include xxLarge {
    .blockTwoColumn {
        --column-lastChild-padding: var(--block-padding);
    }
}
