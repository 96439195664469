@import 'styles/colors';
@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    display: grid;
    max-width: var(--block-max-width);
    align-items: center;
    padding: var(--block-padding-end) 2.4rem;
    margin: 0 auto;
    color: var(--colors-blue);
    grid-auto-columns: 1fr;
    grid-gap: 3.6rem;
    grid-template-columns: var(--grid-template);
    --grid-template: repeat(1, 1fr);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    picture {
        order: -1;
        grid-column: 1 / -1;
    }
}

.card {
    grid-column: 1 / -1;
}

.cta {
    width: max-content;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    object-fit: cover;
}

.eyebrow {
    margin: 0 0 2.4rem;
    text-transform: uppercase;

    p {
        margin: 0;
    }
}

.headline {
    max-width: 42rem;
    text-transform: uppercase;
}

.body {
    padding: 2.4rem 0;

    p {
        max-width: 55rem;
        margin: 0 0 2.4rem;

        &:last-child {
            margin: 0;
        }
    }
}

.imageRight {
    .card {
        order: -1;
    }
}

@include medium {
    .root {
        padding: var(--block-padding);
    }
}

@include large {
    .root {
        --grid-template: repeat(14, 1fr);
        grid-gap: 1.2rem;

        picture {
            grid-column: 1 / 9;
        }
    }

    .imageRight {
        direction: rtl;

        .card {
            order: unset;
            direction: ltr;
        }
    }

    .body {
        p {
            max-width: 38rem;
        }
    }

    .card {
        grid-column: 10 / -1;
    }
}
