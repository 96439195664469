@import 'styles/breakpoints';
@import 'styles/mixins';

.linkWrapper {
    width: 100%;
    height: 100%;
}

.commitmentCard {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    background-color: var(--colors-white);

    @include rounded-corners($overflow: hidden);
}

.imageWrapper {
    position: relative;
    width: 100%;
}

.image {
    z-index: 1;
    width: 100%;
    height: auto;
}

.title {
    position: relative;
    text-align: center;
    text-transform: uppercase;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: var(--content-justify, center);
    padding: var(--content-padding, 5.1rem 2.4rem var(--block-padding-end));
    color: var(--colors-blue);
    text-align: center;
}

.iconWrapper {
    position: relative;
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
}

.description {
    margin-top: 1rem;
}

.commitmentLink {
    margin-top: 2.5rem;
    text-decoration: underline;
    text-transform: uppercase;
}

@include large {
    .iconWrapper {
        position: relative;
        width: 6rem;
        height: 6rem;
    }

    .contentWrapper {
        --content-padding: 5.5rem 4.8rem 6rem;

        height: 100%;

        --content-justify: space-between;
    }
}
