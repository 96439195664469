@import 'styles/breakpoints';

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--colors-blue);
    padding: var(
        --root-padding,
        var(--block-padding-end) 2.4rem var(--last-block-padding-bottom)
    );
    max-width: var(--block-max-width);
    margin: 0 auto;

    &.flushBottom {
        padding-bottom: 0;
    }

    &.flushTop {
        padding-top: 0;
    }
}

.headline {
    text-transform: uppercase;
    margin-bottom: 2.4rem;
    max-width: var(--headline-max-width, none);
}

.description {
    margin-bottom: 0;
    max-width: var(--description-max-width, 30rem);
}

.loadingMessage {
    margin-bottom: 0;
}

.icon {
    width: 13rem;
    height: 13rem;
}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2.4rem;
}

.leadingButton {
    margin-bottom: 1.2rem;
}

.banner {
    margin-top: 2.4rem;
}

.loadingMessage {
    padding: 6rem 0;
}

@include medium {
    .root {
        --root-padding: var(--block-padding-end) var(--block-padding-side);
        --description-max-width: 42rem;
        --headline-max-width: 65rem;

        &.flushBottom {
            padding-bottom: 0;
        }

        &.flushTop {
            padding-top: 0;
        }
    }
}

@include large {
    .headline {
        max-width: 76rem;
    }

    .description {
        max-width: 60rem;
    }

    .actions {
        flex-direction: row;
    }

    .leadingButton {
        margin-bottom: 0;
        margin-right: 2.4rem;
    }
}
