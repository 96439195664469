@import 'styles/breakpoints';

.twoColumnTextFlex {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.twoColumnText {
    position: relative;
    width: 100%;
    color: var(--colors-blue);
    max-width: var(--block-max-width);
    display: var(--twoColumnText-display, block);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--twoColumnText-padding, 4.8rem 0);

    .flushTop & {
        padding-top: 0;
    }

    .flushBottom & {
        padding-bottom: 0;
    }
}

.column {
    width: var(--column-width, 100%);
    display: flex;
    flex-direction: column;
    align-items: var(--column-align-items, center);
    justify-content: center;
    text-align: var(--column-text-align, center);
    margin: var(--column-margin, 0 0 var(--block-padding-end));
    padding: var(--column-padding, 0 2.4rem);

    &:first-child {
        align-items: center;
        margin: var(--column-first-child-margin, 0 0);
        padding: var(
            --column-first-child-padding,
            var(--block-padding-end) var(--block-padding-side) 0
        );
    }

    &.largeHeading {
        margin: var(--column-margin, 0);

        &:first-child {
            padding: var(--column-first-child-padding, 0 2.4rem 1.5rem);
        }

        .flushTop & {
            padding-top: 0;
        }

        .flushBottom & {
            padding-bottom: 0;
        }
    }

    .flushTop & {
        padding-top: 0;
    }

    .flushBottom & {
        padding-bottom: 0;
    }
}

.paragraph:last-child {
    margin-bottom: 0;
}

.heading3 {
    margin: var(--heading-three-margin, 2rem 0);
}

.columnInner {
    max-width: 49.2rem;
}

.richEyebrow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

.headline {
    margin-top: var(--headline-margin, 1.2rem);
    max-width: var(--headline-max-width, 48rem);

    .line {
        display: block;
    }
}

.buttonWrapper {
    position: relative;
    margin-top: var(--buttonWrapper-margin-top, 3.4rem);
    width: 100%;
    display: flex;
    justify-content: var(--buttonWrapper-justify-content, center);
}

.tillatipFlex {
    position: relative;
    width: 100%;
    padding: 2rem 0 1rem;
}

.tillatip {
    position: relative;
    width: 100%;
    background-color: var(--colors-white);
    padding: 2rem 2rem 2rem 6rem;
    border-radius: 1rem;
    text-align: left;
}

.tillatipIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;
    width: 2.6rem;
    height: 3.1rem;
    color: var(--colors-blue);
}

.tillatipLabel {
    text-transform: uppercase;
}

@include medium {
    .twoColumnText {
        --twoColumnText-display: flex;
        --twoColumnText-padding: 0;
        --column-width: 50%;
        --column-text-align: left;
        --column-align-items: flex-start;
        --column-margin: 0;
        --column-padding: var(--block-padding-end) var(--block-padding-side)
            var(--block-padding-end) 2.4rem;
        --column-first-child-margin: 0;
        --column-first-child-padding: var(--block-padding-end) 2.4rem
            var(--block-padding-end) var(--block-padding-side);
        --heading-three-margin: 0 0 2rem;
        --headline-margin: 2.2rem;
        --headline-max-width: none;

        flex-direction: row;

        --buttonWrapper-justify-content: flex-start;
        --buttonWrapper-margin-top: 4.4rem;
    }
}

@include xxLarge {
    .twoColumnText {
        --column-padding: 9.6rem var(--block-padding-side) 9.6rem 2.4rem;
        --column-first-child-padding: 9.6rem 4.8rem 9.6rem
            var(--block-padding-side);
    }
}
