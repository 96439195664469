.missing {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);

    // align-items: center;
    justify-content: center;
    padding: 0 var(--block-padding);
    margin: 0 auto;

    .inner {
        display: flex;
        width: 100%;
        height: 100px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(--block-padding);
        border: 5px solid #a44;
        background-color: rgba(#a44, 0.4);
        color: #822;
        font-size: 1.2em;
        line-height: 150%;
        text-align: center;
    }
}
