@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 2.4rem;
    background-color: var(--background-color);
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    display: flex;
    width: 100%;
    max-width: 81.4rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headline {
    .hasCopy & {
        padding-bottom: 3rem;
    }

    text-transform: uppercase;
}

.copy {
    text-align: center;
}

.paragraph {
    margin: 0;
}

.cta {
    margin-top: 3rem;
}

@include medium {
    .root {
        padding: var(--block-padding);
    }

    .cta {
        margin-top: 4.8rem;
    }
}

@include large {
    .inner {
        .textAlignLeft & {
            max-width: var(--block-max-width);
            align-items: flex-start;
        }
    }

    .headline {
        .textAlignLeft & {
            max-width: 81.4rem;
        }
    }

    .copy {
        .textAlignLeft & {
            max-width: 81.4rem;
            text-align: left;
        }
    }

    .paragraph {
        .textAlignLeft & {
            text-align: left;
        }
    }
}
