@import 'styles/breakpoints';
@import 'styles/mixins';

.blockSimpleImageParagraph {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-white);
    padding: var(--block-padding-end);

    @include rounded-corners;
}

.image {
    max-width: 22.4rem;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2.8rem 0 0;
    width: 100%;
    max-width: 49.2rem;
}

.copy {
    color: var(--colors-blue);
}

.buttonWrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 2.4rem;
}

@include large {
    .inner {
        flex-direction: row;
    }

    .content {
        text-align: left;
        display: flex;
        align-items: flex-start;
        margin: 0 0 0 10rem;
    }

    .buttonWrapper {
        justify-content: flex-start;
    }
}
